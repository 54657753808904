// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Form from "../../../styleguide/forms/Form/Form.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as SignUpData from "../../client/sign-up/SignUpData.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Routes_Page from "../../../routes/common/Routes_Page.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactTelInput from "../../../bindings/ReactTelInput.res.js";
import * as TelephoneField from "../../../styleguide/forms/TelephoneField/TelephoneField.res.js";
import * as SignUpEmailError from "../../client/sign-up/errors/SignUpEmailError.res.js";
import * as SignUpFormScss from "./SignUpForm.scss";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignUpLastNameError from "../../client/sign-up/errors/SignUpLastNameError.res.js";
import * as SignUpPasswordError from "../../client/sign-up/errors/SignUpPasswordError.res.js";
import * as SignUpAgreementError from "../../client/sign-up/errors/SignUpAgreementError.res.js";
import * as SignUpFirstNameError from "../../client/sign-up/errors/SignUpFirstNameError.res.js";
import * as SignUpCompanyNameError from "../../client/sign-up/errors/SignUpCompanyNameError.res.js";
import * as SignUpPhoneNumberError from "../../client/sign-up/errors/SignUpPhoneNumberError.res.js";
import * as SignUpPasswordConfirmError from "../../client/sign-up/errors/SignUpPasswordConfirmError.res.js";

var css = SignUpFormScss;

function reducer(state, action) {
  if (typeof action !== "object") {
    switch (action) {
      case "SetIsSubmitted" :
          return {
                  selectedTab: state.selectedTab,
                  isSubmitted: true,
                  input: state.input,
                  results: state.results,
                  errorMessage: state.errorMessage,
                  isPendingInvitation: state.isPendingInvitation,
                  resendInvitation: state.resendInvitation
                };
      case "AfterSubmit" :
          return {
                  selectedTab: state.selectedTab,
                  isSubmitted: false,
                  input: state.input,
                  results: state.results,
                  errorMessage: state.errorMessage,
                  isPendingInvitation: state.isPendingInvitation,
                  resendInvitation: state.resendInvitation
                };
      case "ResendInvitation" :
          return {
                  selectedTab: state.selectedTab,
                  isSubmitted: state.isSubmitted,
                  input: state.input,
                  results: SignUpData.Validate.all(state.input),
                  errorMessage: state.errorMessage,
                  isPendingInvitation: state.isPendingInvitation,
                  resendInvitation: state.resendInvitation
                };
      case "AfterResendInvitation" :
          return {
                  selectedTab: state.selectedTab,
                  isSubmitted: state.isSubmitted,
                  input: state.input,
                  results: state.results,
                  errorMessage: state.errorMessage,
                  isPendingInvitation: false,
                  resendInvitation: true
                };
      
    }
  } else {
    switch (action.TAG) {
      case "SelectTab" :
          return {
                  selectedTab: action._0,
                  isSubmitted: state.isSubmitted,
                  input: SignUpData.Input.empty(),
                  results: undefined,
                  errorMessage: state.errorMessage,
                  isPendingInvitation: state.isPendingInvitation,
                  resendInvitation: state.resendInvitation
                };
      case "Update" :
          return {
                  selectedTab: state.selectedTab,
                  isSubmitted: state.isSubmitted,
                  input: action._0,
                  results: state.results,
                  errorMessage: state.errorMessage,
                  isPendingInvitation: false,
                  resendInvitation: false
                };
      case "InvitationPendingError" :
          return {
                  selectedTab: state.selectedTab,
                  isSubmitted: false,
                  input: state.input,
                  results: state.results,
                  errorMessage: "",
                  isPendingInvitation: action._0,
                  resendInvitation: false
                };
      case "SetErrorMessage" :
          return {
                  selectedTab: state.selectedTab,
                  isSubmitted: state.isSubmitted,
                  input: state.input,
                  results: state.results,
                  errorMessage: action._0,
                  isPendingInvitation: false,
                  resendInvitation: false
                };
      case "SetResults" :
          return {
                  selectedTab: state.selectedTab,
                  isSubmitted: state.isSubmitted,
                  input: state.input,
                  results: action._0,
                  errorMessage: state.errorMessage,
                  isPendingInvitation: state.isPendingInvitation,
                  resendInvitation: state.resendInvitation
                };
      
    }
  }
}

function SignUpForm(props) {
  var form = props.form;
  var onSignIn = props.onSignIn;
  var onSignedUp = props.onSignedUp;
  var match = React.useReducer(reducer, {
        selectedTab: window.location.hash === "#provider" ? "Provider" : "Individual",
        isSubmitted: false,
        input: SignUpData.Input.empty(),
        results: undefined,
        errorMessage: "",
        isPendingInvitation: false,
        resendInvitation: false
      });
  var dispatch = match[1];
  var state = match[0];
  var serverError = function (errorMessage) {
    dispatch({
          TAG: "SetErrorMessage",
          _0: errorMessage
        });
    dispatch("AfterSubmit");
  };
  var onSuccessSignUpModal = function (res) {
    var data = res._0;
    var invitationPending = data.invitationPending;
    if (invitationPending !== undefined) {
      return dispatch({
                  TAG: "InvitationPendingError",
                  _0: invitationPending
                });
    } else {
      return onSignedUp(data.user);
    }
  };
  var onSuccessSignUp = function (res) {
    var invitationPending = res._0.invitationPending;
    if (invitationPending !== undefined) {
      return dispatch({
                  TAG: "InvitationPendingError",
                  _0: invitationPending
                });
    }
    var match = state.selectedTab;
    if (match === "Individual") {
      return Url.visit(Routes_Page.thanks);
    } else {
      return Url.visit(Routes_Page.thanksProvider);
    }
  };
  var beforeSubmit = function () {
    dispatch("SetIsSubmitted");
    var input = state.input;
    var selectedTab = state.selectedTab;
    var results = SignUpData.Validate.all(input);
    dispatch({
          TAG: "SetResults",
          _0: results
        });
    if (SignUpData.Validate.valid(results)) {
      return $$Promise.wait(Api.signUp(input, selectedTab), (function (x) {
                    if (x.TAG === "Ok") {
                      var res = x._0;
                      if (form === "SignUp") {
                        onSuccessSignUp(res);
                      } else {
                        onSuccessSignUpModal(res);
                      }
                      return dispatch("AfterSubmit");
                    }
                    SentryLogger.error1({
                          rootModule: "SignUpForm",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "SignUpForm.make"
                        }, "SignUpModal::Error", [
                          "Error",
                          x._0
                        ]);
                    serverError("Something went wrong");
                  }));
    } else {
      return dispatch("AfterSubmit");
    }
  };
  var resendInvitation = function () {
    dispatch("ResendInvitation");
    $$Promise.wait(Api.resendInvitation(state.input.email), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch("AfterResendInvitation");
            }
            SentryLogger.error1({
                  rootModule: "SignUpForm",
                  subModulePath: /* [] */0,
                  value: "make",
                  fullPath: "SignUpForm.make"
                }, "ResendInvitation::Error", [
                  "Error",
                  x._0
                ]);
            serverError("Something went wrong");
          }));
  };
  var match$1 = state.selectedTab;
  var tmp;
  tmp = match$1 === "Individual" ? css.active : "";
  var match$2 = state.selectedTab;
  var tmp$1;
  tmp$1 = match$2 === "Individual" ? "" : css.active;
  var match$3 = state.errorMessage;
  var tmp$2 = match$3 === "" ? null : JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx("p", {
                      children: state.errorMessage
                    }),
                className: css.errors
              }),
          className: css.errorContainer
        });
  var res = state.results;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("ul", {
                            children: [
                              JsxRuntime.jsx("li", {
                                    children: JsxRuntime.jsx("a", {
                                          children: "Individual",
                                          className: Cx.cx([tmp]),
                                          onClick: (function (param) {
                                              dispatch({
                                                    TAG: "SelectTab",
                                                    _0: "Individual"
                                                  });
                                            })
                                        })
                                  }),
                              JsxRuntime.jsx("li", {
                                    children: JsxRuntime.jsx("a", {
                                          children: "Provider",
                                          className: Cx.cx([tmp$1]),
                                          onClick: (function (param) {
                                              dispatch({
                                                    TAG: "SelectTab",
                                                    _0: "Provider"
                                                  });
                                            })
                                        })
                                  })
                            ]
                          }),
                      className: css.tabsContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: state.selectedTab === "Individual" ? "Sign up to get full access to the site and be able to download resources and send multiple quotes." : "Sign up as a provider to list your company and get warm leads. You'll be able to add your locations, and products to the marketplace.",
                      className: css.signUpInfo
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        "Already have an account?  ",
                        JsxRuntime.jsx(Control.make, {
                              onClick: (function (param) {
                                  onSignIn();
                                }),
                              children: "Sign In"
                            })
                      ],
                      className: css.toSignIn
                    }),
                JsxRuntime.jsxs(Form.make, {
                      className: css.signUpForm,
                      onSubmit: (function (param) {
                          beforeSubmit();
                        }),
                      children: [
                        state.isPendingInvitation ? JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: "You have a pending invitation to Datacenters.com. Take the following steps to complete sign up:"
                                      }),
                                  JsxRuntime.jsxs("ul", {
                                        children: [
                                          JsxRuntime.jsxs("li", {
                                                children: [
                                                  JsxRuntime.jsx(Control.make, {
                                                        className: css.resendInvitationButton,
                                                        onClick: (function (param) {
                                                            resendInvitation();
                                                          }),
                                                        children: "Click here "
                                                      }),
                                                  "to resend the invitation."
                                                ]
                                              }),
                                          JsxRuntime.jsx("li", {
                                                children: "Follow the instructions in the email."
                                              }),
                                          JsxRuntime.jsx("li", {
                                                children: "Questions? Call 1-833-471-7100."
                                              })
                                        ]
                                      })
                                ],
                                className: css.message
                              }) : null,
                        state.resendInvitation ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("p", {
                                            children: "Resend invitation link successfully."
                                          }),
                                      className: css.success
                                    }),
                                className: Cx.cx([
                                      css.messageContainer,
                                      css.success
                                    ])
                              }) : null,
                        tmp$2,
                        res !== undefined && !SignUpData.Validate.valid(res) ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("p", {
                                            children: "Please, fix all errors."
                                          }),
                                      className: css.errors
                                    }),
                                className: css.errorContainer
                              }) : null,
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Label.make, {
                                              forId: "signUpModal-form--first-name",
                                              children: "First name"
                                            }),
                                        JsxRuntime.jsx(TextField.make, {
                                              id: "signUpModal-form--first-name",
                                              value: state.input.firstName,
                                              status: Belt_Option.map(state.results, (function (x) {
                                                      if (x.firstName.TAG === "Ok") {
                                                        return "Valid";
                                                      } else {
                                                        return "Error";
                                                      }
                                                    })),
                                              onChange: (function ($$event) {
                                                  var init = state.input;
                                                  dispatch({
                                                        TAG: "Update",
                                                        _0: {
                                                          firstName: $$event.target.value,
                                                          lastName: init.lastName,
                                                          email: init.email,
                                                          companyName: init.companyName,
                                                          phoneNumber: init.phoneNumber,
                                                          dialCountryCode: init.dialCountryCode,
                                                          country: init.country,
                                                          password: init.password,
                                                          passwordConfirm: init.passwordConfirm,
                                                          isAgreeWithTerms: init.isAgreeWithTerms,
                                                          isReceiveNews: init.isReceiveNews
                                                        }
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(SignUpFirstNameError.make, {
                                              results: state.results
                                            })
                                      ],
                                      className: css.fieldColumn
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Label.make, {
                                              forId: "signUpModal-form--last-name",
                                              children: "Last name"
                                            }),
                                        JsxRuntime.jsx(TextField.make, {
                                              id: "signUpModal-form--last-name",
                                              value: state.input.lastName,
                                              status: Belt_Option.map(state.results, (function (x) {
                                                      if (x.lastName.TAG === "Ok") {
                                                        return "Valid";
                                                      } else {
                                                        return "Error";
                                                      }
                                                    })),
                                              onChange: (function ($$event) {
                                                  var init = state.input;
                                                  dispatch({
                                                        TAG: "Update",
                                                        _0: {
                                                          firstName: init.firstName,
                                                          lastName: $$event.target.value,
                                                          email: init.email,
                                                          companyName: init.companyName,
                                                          phoneNumber: init.phoneNumber,
                                                          dialCountryCode: init.dialCountryCode,
                                                          country: init.country,
                                                          password: init.password,
                                                          passwordConfirm: init.passwordConfirm,
                                                          isAgreeWithTerms: init.isAgreeWithTerms,
                                                          isReceiveNews: init.isReceiveNews
                                                        }
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(SignUpLastNameError.make, {
                                              results: state.results
                                            })
                                      ],
                                      className: css.fieldColumn
                                    })
                              ],
                              className: Cx.cx([
                                    css.fieldRow,
                                    css.multiple
                                  ])
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "signUpModal-form--email",
                                      children: "Email"
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "signUpModal-form--email",
                                      value: state.input.email,
                                      status: Belt_Option.map(state.results, (function (x) {
                                              if (x.email.TAG === "Ok") {
                                                return "Valid";
                                              } else {
                                                return "Error";
                                              }
                                            })),
                                      onChange: (function ($$event) {
                                          var init = state.input;
                                          dispatch({
                                                TAG: "Update",
                                                _0: {
                                                  firstName: init.firstName,
                                                  lastName: init.lastName,
                                                  email: $$event.target.value,
                                                  companyName: init.companyName,
                                                  phoneNumber: init.phoneNumber,
                                                  dialCountryCode: init.dialCountryCode,
                                                  country: init.country,
                                                  password: init.password,
                                                  passwordConfirm: init.passwordConfirm,
                                                  isAgreeWithTerms: init.isAgreeWithTerms,
                                                  isReceiveNews: init.isReceiveNews
                                                }
                                              });
                                        })
                                    }),
                                JsxRuntime.jsx(SignUpEmailError.make, {
                                      results: state.results
                                    })
                              ],
                              className: css.fieldRow
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "signUpModal-form--phone",
                                            children: "Phone"
                                          }),
                                      JsxRuntime.jsx(TelephoneField.make, {
                                            id: "signUpModal-form--phone",
                                            value: state.input.phoneNumber,
                                            defaultCountry: "us",
                                            status: Belt_Option.map(state.results, (function (x) {
                                                    if (x.phoneNumber.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function (value, selectedCountry) {
                                                var country = ReactTelInput.ReactTelInputData.countryFromJs(selectedCountry);
                                                var init = state.input;
                                                dispatch({
                                                      TAG: "Update",
                                                      _0: {
                                                        firstName: init.firstName,
                                                        lastName: init.lastName,
                                                        email: init.email,
                                                        companyName: init.companyName,
                                                        phoneNumber: "+" + (country.dialCode + Js_string.replace("+" + country.dialCode, "", value).trim()),
                                                        dialCountryCode: "+" + country.dialCode,
                                                        country: country.iso2.toUpperCase(),
                                                        password: init.password,
                                                        passwordConfirm: init.passwordConfirm,
                                                        isAgreeWithTerms: init.isAgreeWithTerms,
                                                        isReceiveNews: init.isReceiveNews
                                                      }
                                                    });
                                              }),
                                            preferredCountries: ["us"]
                                          }),
                                      JsxRuntime.jsx(SignUpPhoneNumberError.make, {
                                            results: state.results
                                          })
                                    ],
                                    className: Cx.cx([
                                          css.fieldColumn,
                                          css.half
                                        ])
                                  }),
                              className: css.fieldRow
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "signUpModal-form--company-name",
                                      children: "Company name"
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "signUpModal-form--company-name",
                                      value: state.input.companyName,
                                      status: Belt_Option.map(state.results, (function (x) {
                                              if (x.companyName.TAG === "Ok") {
                                                return "Valid";
                                              } else {
                                                return "Error";
                                              }
                                            })),
                                      onChange: (function ($$event) {
                                          var init = state.input;
                                          dispatch({
                                                TAG: "Update",
                                                _0: {
                                                  firstName: init.firstName,
                                                  lastName: init.lastName,
                                                  email: init.email,
                                                  companyName: $$event.target.value,
                                                  phoneNumber: init.phoneNumber,
                                                  dialCountryCode: init.dialCountryCode,
                                                  country: init.country,
                                                  password: init.password,
                                                  passwordConfirm: init.passwordConfirm,
                                                  isAgreeWithTerms: init.isAgreeWithTerms,
                                                  isReceiveNews: init.isReceiveNews
                                                }
                                              });
                                        })
                                    }),
                                JsxRuntime.jsx(SignUpCompanyNameError.make, {
                                      results: state.results
                                    })
                              ],
                              className: css.fieldRow
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Label.make, {
                                              forId: "signUpModal-form--password",
                                              children: "Password"
                                            }),
                                        JsxRuntime.jsx(TextField.make, {
                                              id: "signUpModal-form--password",
                                              type_: "Password",
                                              value: state.input.password,
                                              status: Belt_Option.map(state.results, (function (x) {
                                                      if (x.password.TAG === "Ok") {
                                                        return "Valid";
                                                      } else {
                                                        return "Error";
                                                      }
                                                    })),
                                              onChange: (function ($$event) {
                                                  var init = state.input;
                                                  dispatch({
                                                        TAG: "Update",
                                                        _0: {
                                                          firstName: init.firstName,
                                                          lastName: init.lastName,
                                                          email: init.email,
                                                          companyName: init.companyName,
                                                          phoneNumber: init.phoneNumber,
                                                          dialCountryCode: init.dialCountryCode,
                                                          country: init.country,
                                                          password: $$event.target.value,
                                                          passwordConfirm: init.passwordConfirm,
                                                          isAgreeWithTerms: init.isAgreeWithTerms,
                                                          isReceiveNews: init.isReceiveNews
                                                        }
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(SignUpPasswordError.make, {
                                              results: state.results
                                            })
                                      ],
                                      className: css.fieldColumn
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Label.make, {
                                              forId: "signUpModal-form--password-confirm",
                                              children: "Confirm password"
                                            }),
                                        JsxRuntime.jsx(TextField.make, {
                                              id: "signUpModal-form--password-confirm",
                                              type_: "Password",
                                              value: state.input.passwordConfirm,
                                              status: Belt_Option.map(state.results, (function (x) {
                                                      if (x.passwordConfirm.TAG === "Ok") {
                                                        return "Valid";
                                                      } else {
                                                        return "Error";
                                                      }
                                                    })),
                                              onChange: (function ($$event) {
                                                  var init = state.input;
                                                  dispatch({
                                                        TAG: "Update",
                                                        _0: {
                                                          firstName: init.firstName,
                                                          lastName: init.lastName,
                                                          email: init.email,
                                                          companyName: init.companyName,
                                                          phoneNumber: init.phoneNumber,
                                                          dialCountryCode: init.dialCountryCode,
                                                          country: init.country,
                                                          password: init.password,
                                                          passwordConfirm: $$event.target.value,
                                                          isAgreeWithTerms: init.isAgreeWithTerms,
                                                          isReceiveNews: init.isReceiveNews
                                                        }
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(SignUpPasswordConfirmError.make, {
                                              results: state.results
                                            })
                                      ],
                                      className: css.fieldColumn
                                    })
                              ],
                              className: Cx.cx([
                                    css.fieldRow,
                                    css.multiple
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs(Checkbox.WithLabel.make, {
                                            id: "signUpModal-form--agree",
                                            size: "MD",
                                            checked: state.input.isAgreeWithTerms,
                                            disabled: false,
                                            visuallyDisabled: false,
                                            onChange: (function (param) {
                                                var init = state.input;
                                                dispatch({
                                                      TAG: "Update",
                                                      _0: {
                                                        firstName: init.firstName,
                                                        lastName: init.lastName,
                                                        email: init.email,
                                                        companyName: init.companyName,
                                                        phoneNumber: init.phoneNumber,
                                                        dialCountryCode: init.dialCountryCode,
                                                        country: init.country,
                                                        password: init.password,
                                                        passwordConfirm: init.passwordConfirm,
                                                        isAgreeWithTerms: !state.input.isAgreeWithTerms,
                                                        isReceiveNews: init.isReceiveNews
                                                      }
                                                    });
                                              }),
                                            children: [
                                              "I agree to the ",
                                              JsxRuntime.jsx("a", {
                                                    children: "Terms & Conditions",
                                                    href: "/terms-of-use"
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsx(SignUpAgreementError.make, {
                                            results: state.results
                                          })
                                    ],
                                    className: css.checkboxLabel
                                  }),
                              className: css.fieldRow
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                          id: "signUpModal-form--news",
                                          size: "MD",
                                          checked: state.input.isReceiveNews,
                                          disabled: false,
                                          visuallyDisabled: false,
                                          onChange: (function (param) {
                                              var init = state.input;
                                              dispatch({
                                                    TAG: "Update",
                                                    _0: {
                                                      firstName: init.firstName,
                                                      lastName: init.lastName,
                                                      email: init.email,
                                                      companyName: init.companyName,
                                                      phoneNumber: init.phoneNumber,
                                                      dialCountryCode: init.dialCountryCode,
                                                      country: init.country,
                                                      password: init.password,
                                                      passwordConfirm: init.passwordConfirm,
                                                      isAgreeWithTerms: init.isAgreeWithTerms,
                                                      isReceiveNews: !state.input.isReceiveNews
                                                    }
                                                  });
                                            }),
                                          children: "Receive latest industry news"
                                        }),
                                    className: css.checkboxLabel
                                  }),
                              className: css.fieldRow
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Primary",
                              busy: state.isSubmitted,
                              submit: true,
                              className: css.submitButton,
                              children: "Sign Up"
                            })
                      ]
                    })
              ]
            });
}

var Input;

var Validate;

var ValidationResult;

var ReactTelInputData;

var make = SignUpForm;

export {
  css ,
  Input ,
  Validate ,
  ValidationResult ,
  ReactTelInputData ,
  reducer ,
  make ,
}
/* css Not a pure module */
